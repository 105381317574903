import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router'; // Importa el router

const app = createApp(App);

app.use(router); // Usa el router

app.mount('#app');

// C:\Users\GUIBOX\Documents\Vue.js_Proyectos\3d-imagine