<template>
  <section class="main">
    <header class="header">
      <div class="logo">3D-IMAGINE</div>
      <nav class="nav">
        <ul>
          <li><a><router-link to="/">INICIO</router-link></a></li>
          <li><a href="#catalogo">CATALOGO</a></li>
          <li><a><router-link to="/ContactoPage">CONTACTO</router-link></a></li>
        </ul>
      </nav>
    </header>
  </section>
  </template>
  
  <script>
  export default {
    name: "HeaderPage",
  };
  </script>
  
  <style scoped>
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 5px;
  }

  .header {
    background: linear-gradient(90deg, rgba(245,60,114,1) 0%, rgba(122,0,216,1) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
    width: 96%;
    height: auto;
    border-radius: 15px;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .nav ul {
    list-style-type: none;
    display: flex;
    gap: 20px;
  }
  
  .nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .nav ul li a:hover {
    text-decoration: underline;
  }
  </style>