<template>
  <div id="app">
    <Header />
    <router-view></router-view> <!-- Aquí se renderizarán las vistas -->
  </div>
</template>

<script>
import Header from './components/HeaderPage.vue';

export default {
  name: 'App',
  components: {
    Header
  }
};
</script>

<style>
/* Estilos globales opcionales */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}
</style>