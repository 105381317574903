// router.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue'; // Asegúrate de que las rutas sean correctas
import ContactoPage from '../components/ContactoPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/ContactoPage',
    name: 'ContactoPage',
    component: ContactoPage
  }
];

const router = createRouter({
  history: createWebHistory(), // Usar el historial HTML5
  routes
});

export default router;
