<template>
    <section class="home">
      <div class="hero">
        <h1>CONTACTO</h1>
        <p></p>
        <div class="pricing-toggle">
        </div>
      </div>
    </section>
</template>
  
  <script>
  export default {
    name: "ContactoPage",
  };
  </script>
  
  <style scoped>
  .home {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    margin-top: 50px;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
  }
  
  .hero {
    background: linear-gradient(180deg, rgba(245,60,114,1) 0%, rgba(122,0,216,1) 100%);
    width: 96%;
    height: auto;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .hero p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    width: 90%;
  }
  
  .pricing-toggle {
    display: inline-block;
    margin: 20px 0;
  }
  
  .toggle-btn {
    background-color: white;
    border: none;
    color: rgba(245,60,114,1);
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .toggle-btn.active {
    background-color: rgba(245,60,114,1);
    color: white;
  }
  
  .toggle-btn:hover {
    opacity: 0.8;
  }
  </style>